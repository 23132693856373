export const endpoints = {
  counties: '/counties',
  login: '/login',
  question: '/question',
  register: '/register',
  reset: '/reset-password',
  tutorial: '/tutorial',
  user: '/user',
  userQuiz: '/user/quiz',
};
