import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentWrapper } from '../../components';
import { QuestionForm } from '../../components/QuestionForm';
import {
  useInvalidateQuizQuery,
  useQuestionMutation,
  useQuestionQuery,
  useQuizQuery,
} from '../../queries';
import { useAuth } from '../../store';
import { ResponseKeys } from '../../types';
import { AppRoutes } from '../../types/routes';
import { isContestEnded, isContestStarted } from '../../utils';

export const QuizQuestionPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { accessToken } = useAuth();

  const [selectedAnswer, setSelectedAnswer] = useState<ResponseKeys>();

  const { data: quiz, isFetching: isLoadingQuiz } = useQuizQuery();
  const invalidateQuiz = useInvalidateQuizQuery();

  const { data, isLoading, isFetching, isError } = useQuestionQuery(id, {
    onError: (error) => {
      if (error) {
        navigate(`/quiz/${(quiz?.total_number_of_responses ?? 0) + 1}`);
      }
    },
    retry: false,
  });

  const isLoadingQuestion = isLoading || isFetching;

  const { mutate: answerQuestion, isLoading: isValidatingAnswer } = useQuestionMutation();

  const onAnswerQuestionHandler = useCallback(
    (response: ResponseKeys) => {
      if (!id || !accessToken) return;

      answerQuestion(
        { response, id, accessToken },
        {
          onSuccess: () => {
            invalidateQuiz();
            setSelectedAnswer(undefined);
            if (parseInt(id, 10) < 50) {
              navigate(`/quiz/${parseInt(id, 10) + 1}`, { replace: true });
            } else {
              navigate(AppRoutes.Quiz);
            }
          },
          onError: (error) => {
            if (error.message.toLowerCase() === 'forbidden') {
              if (quiz?.total_number_of_responses) {
                navigate(`/quiz/${quiz.total_number_of_responses + 1}`);
              }
            }
          },
        },
      );
    },
    [accessToken, answerQuestion, id, invalidateQuiz, navigate, quiz?.total_number_of_responses],
  );

  useEffect(() => {
    if (!isContestStarted() || isContestEnded()) {
      navigate(AppRoutes.Home, { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isContestEnded()) {
        clearInterval(intervalId);
        navigate(AppRoutes.Quiz, { replace: true });
      }
    });

    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    if (
      !quiz?.total_number_of_responses ||
      (id && quiz.total_number_of_responses === parseInt(id, 10) - 1) ||
      isLoadingQuiz ||
      isLoadingQuestion
    ) {
      return;
    }
    if (quiz.total_number_of_responses < 50) {
      navigate(`/quiz/${quiz.total_number_of_responses + 1}`);
    } else {
      navigate(AppRoutes.Quiz);
    }
  }, [id, isLoadingQuestion, isLoadingQuiz, navigate, quiz?.total_number_of_responses]);

  useEffect(() => {
    const onUnload = (event: Event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = false;
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  return (
    <ContentWrapper>
      <QuestionForm
        isLoading={isLoadingQuestion}
        isValidating={isValidatingAnswer}
        onAnswer={onAnswerQuestionHandler}
        onAnswerChange={setSelectedAnswer}
        question={data}
        selectedAnswer={selectedAnswer}
        isError={isError}
      />
    </ContentWrapper>
  );
};
