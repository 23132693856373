import { FC } from 'react';

type InputLabelProps = {
  name?: string;
  required?: boolean;
};

export const InputLabel: FC<InputLabelProps> = ({ children, name, required }) => {
  return (
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {children}

      {required && <span className="text-red-500 font-semibold">*</span>}
    </label>
  );
};
