import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components';
import { AppRoutes } from '../../types/routes';

export const RulesPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.Quiz);
  }, [navigate]);

  return (
    <ContentWrapper>
      <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
        REGULAMENTUL SPECIFIC DE ORGANIZARE ȘI DESFĂȘURARE A CONCURSULUI NAȚIONAL EURO QUIZ
      </h2>
      <h3 className="text-md sm:text-lg">-valabil pentru anul școlar 2023-2024- </h3>
      <br />
      <p>
        În cadrul inițiativei „Campaniile Educaționale pe teme legate de U.E.” Reprezentanța
        Comisiei Europene în România, în parteneriat cu Ministerul Educației, organizează concursul
        național „Euro Quiz”. Competiția este cuprinsă în{' '}
        <span className="italic">
          Calendarul concursurilor naționale finanțate de către ME- 2023-2024, aprobat de ME cu nr.
          24749/29.01.2024, la pct. 2, poziția 9
        </span>{' '}
        și se adresează tuturor elevilor din învățământul gimnazial (clasele V-VIII) din România,
        conform următoarelor reguli:
      </p>
      <br />
      <h3 className="h3-title">Articolul I - Tematică și obiective</h3>
      <p>
        Concursul „Euro Quiz” este un concurs național online, cu întrebări și răspunsuri despre
        trecutul, prezentul și viitorul Uniunii Europene, adresat elevilor din învățământul
        gimnazial din întreaga țară, prin intermediul căruia Reprezentanța Comisiei Europene în
        România se apropie de copii și tineri și de familiile acestora.
      </p>
      <br />
      <h3 className="h3-title">Articolul II - Scop</h3>
      <p>
        Concursul „Euro Quiz” are drept scop îmbogățirea cunoștințelor despre Europa și Uniunea
        Europeană, încurajarea lucrului în echipă, conștientizarea valorilor cetățeniei europene
        active prin înțelegerea informată a realităților sociale și dezvoltarea atitudinilor
        social-civice democratice, necesare tinerei generații pentru participarea activă la viața
        socială.
      </p>
      <br />
      <h3 className="h3-title">Articolul III - Grup țintă</h3>
      <p>
        Concursul național „Euro Quiz” se adresează tuturor elevilor din învățământul gimnazial
        (clasele a V-a - a VIII-a).
      </p>
      <br />
      <h3 className="h3-title">Articolul IV - Înscrierea în competiție </h3>
      <p className="mb-2">
        <span className="font-bold">IV.1.</span> Înscrierea în competiție se face prin completarea
        formularului-tip de înscriere disponibil pe platforma concursului{' '}
        <a href={AppRoutes.Home} className="text-primary underline">
          https://euroquiz.ro
        </a>
        . Unitățile de învățământ, prin profesorii coordonatori, completează formularele-tip de
        înscriere la concurs, până la termenul-limită prevăzut pentru înscrierea în competiție. În
        formularele-tip/Creează cont se solicită și componența nominală a echipelor înscrise în
        competiție. În situații bine argumentate, componența echipelor va putea fi modificată și
        după înscrierea în concurs, cu condiția informării și obținerii, în timp util, a acordului
        de modificare din partea organizatorilor. Nu sunt acceptate în concurs echipele înscrise
        după termenul limită de înscriere.
      </p>
      <p className="mb-2">
        <span className="font-bold">IV.2.</span> Fiecare echipă este compusă din patru elevi, din
        aceeași clasă, din clase diferite sau din ani diferiți de studiu, de la aceeași unitate de
        învățământ.
      </p>
      <p className="mb-2">
        <span className="font-bold">IV.3.</span> La etapa județeană se pot înscrie mai multe echipe
        din aceeași unitate de învățământ. Echipele înscrise la concurs trebuie să aibă profesori
        coordonatori diferiți. Un profesor nu poate coordona echipe de la două sau mai multe unități
        de învățământ.
      </p>
      <p className="mb-2">
        <span className="font-bold">IV.4.</span> Poate fi profesor coordonator orice cadru didactic
        care îndeplinește, cumulat, următoarele condiții: a) este cadru didactic în unitatea de
        învățământ din care provin elevii din echipă; b) selectează elevii și înscrie echipa în
        concurs; c) realizează pregătirea elevilor pentru concurs; d) reprezintă echipa în
        competiție; e) asigură comunicarea pe toată perioada derulării competiției.
      </p>
      <p>
        <span className="font-bold">IV.5.</span> Reprezentanța Comisiei Europene în România,
        împreună cu consilierul/inspectorul general din Ministerul Educației care coordonează
        competiția, centralizează înscrierile și transmit Inspectoratelor Școlare Județene/al
        Municipiului București numărul de echipe și numărul de unități școlare înscrise în
        competiție.
      </p>
      <br />
      <h3 className="h3-title">Articolul V - Formatul concursului</h3>
      <p>
        Concursul „Euro Quiz” se desfășoară online, în echipe formate din patru elevi. În timpul
        concursului online, fiecare echipă va trebui să răspundă, într-un interval de timp limitat (
        <span className="font-bold">25 de minute, pentru toate întrebările</span>), la o succesiune
        de 50 de întrebări privind Europa și valorile Uniunii Europene, rolul instituțiilor
        europene, istoria, geografia, arta și gastronomia statelor membre ale U.E. Întrebările din
        concurs sunt întrebări cu răspuns la alegere, iar echipele trebuie să selecteze răspunsul
        corect (toate întrebările au un singur răspuns corect).
      </p>
      <br />
      <h3 className="h3-title">Articolul VI - Desfășurarea concursului</h3>
      <p className="mb-2">
        <span className="font-bold">VI.1.</span> Reprezentanța Comisiei Europene în România și
        Ministerul Educației, prin Inspectoratele Școlare Județene/al Municipiului București
        promovează competiția în toate unitățile de învățământ care au clase de învățământ gimnazial
        și transmit, dacă este cazul, precizări metodologice și detalii tehnice privind organizarea
        etapelor concursului. Reprezentanța Comisiei Europene în România pune la dispoziția
        profesorilor coordonatori materiale informative și resurse educaționale pentru pregătirea
        elevilor.
      </p>
      <p>
        <span className="font-bold">VI.2.</span> Etapa județeană/a municipiului București.
      </p>
      <p>
        <span className="italic">VI.2.1.</span> La această etapă, o unitate de învățământ poate fi
        reprezentată de un număr nelimitat de echipe.
      </p>
      <p>
        <span className="italic">VI.2.2.</span> Etapa județeană se desfășoară în toate județele în
        aceeași zi, în același interval orar. Toate echipele înscrise în competiție intră online la
        ora anunțată de către organizatori.
      </p>
      <p className="mb-2">
        <span className="italic">VI.2.3.</span> În funcție de numărul de echipe participante la
        etapa județeană și de rezultatele obținute de elevi (nr. de puncte acumulate și timpul de
        răspuns, indicat de cronometrul competiției) organizatorii vor anunța echipele calificate
        pentru etapa națională a concursului, prin inspectoratele școlare și prin publicare pe
        pagina dedicată a concursului din website-ul Reprezentanței Comisiei Europene:
        <a
          className="text-primary underline"
          href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
          target="_blank"
          rel="noreferrer"
        >
          https://romania.representation.ec.europa.eu/euro-quiz_ro
        </a>
        .
      </p>
      <p>
        <span className="font-bold">VI.3.</span> Etapa națională
      </p>
      <p>
        <span className="italic">VI.3.1.</span> La etapa națională, toate echipele intră în concurs
        în aceeași zi, la aceeași oră. Aceasta va fi comunicată profesorilor coordonatori odată cu
        anunțarea calificării pentru etapa națională.
      </p>
      <p className="mb-2">
        <span className="italic">VI.3.2.</span> În funcție de rezultatele obținute de elevi (nr. de
        puncte acumulate și timpul de răspuns, indicat de cronometrul competiției) organizatorii vor
        anunța echipele câștigătoare.
      </p>
      <p className="mb-2">
        <span className="font-bold">VI.4.</span> Festivitatea de premiere a concursului național
        „Euro Quiz” va avea loc în luna iunie, la o dată ce va fi comunicată ulterior.
      </p>
      <h3 className="h3-title">Articolul VII - Dispoziții finale</h3>
      <p className="mb-2">
        <span className="font-bold">VII.1.</span> După lansarea competiției, Reprezentanța Comisiei
        Europene în România, în parteneriat cu Ministerul Educației, organizează întâlnirile online,
        care au ca scop informarea și prezentarea formatului și a specificului concursului național
        „Euro Quiz”. La aceste întâlniri online pot participa atât profesori cât și elevi.
        Participarea la aceste întâlniri de informare nu atrage după sine obligația înscrierii în
        competiție.
      </p>
      <p className="mb-2">
        <span className="font-bold">VII.2.</span> Etapele concursului național „Euro Quiz” se
        desfășoară online și nu se admit contestații.
      </p>
      <p className="mb-2">
        <span className="font-bold">VII.3.</span> Toți profesorii coordonatori vor deține și vor
        transmite, la cererea organizatorilor, pentru fiecare elev din echipă, acordul semnat de
        părinte/reprezentant legal (formularul GDPR) pentru utilizarea imaginii și a numelui
        elevilor participanți la concursul național „Euro Quiz”.
      </p>
    </ContentWrapper>
  );
};
