export enum AppRoutes {
  Home = '/',
  Info = '/info',
  Login = '/login',
  Quiz = '/quiz',
  Register = '/register',
  Tutorial = '/tutorial',
  ResetPassword = '/reset-password',
  Rules = '/rules',
}
