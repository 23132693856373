import create from 'zustand';
import { AuthSlice, createAuthSlice } from './createAuthSlice';

export type AppState = AuthSlice;

export const useStore = create<AppState>((set) => ({
  ...createAuthSlice(set),
}));

export default useStore;
