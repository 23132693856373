import { FC } from 'react';
import clsx from 'clsx';

type AnswerBoxProps = {
  label: string;
  onClick: () => void;
  text: string;
  selected?: boolean;
};

const classes = {
  default: 'border cursor-pointer flex hover:bg-gray-100',
  selected: 'border cursor-default flex bg-gray-200',
};

export const AnswerBox: FC<AnswerBoxProps> = ({ label, onClick, text, selected }) => {
  const type = selected ? classes.selected : classes.default;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={clsx('text-lg', type)} onClick={onClick}>
      <div className="border-r px-3 py-3">{label}.</div>
      <div className="flex-grow pl-4 pr-2 py-3">{text}</div>
    </div>
  );
};
