import { HTMLInputTypeAttribute } from 'react';
import { ErrorOption, Path, UseFormRegister } from 'react-hook-form';
import { ErrorLabel } from '../ErrorLabel';
import { Input } from '../Input';
import { InputLabel } from '../InputLabel';

type TextFieldProps<TFormValues> = {
  errors?: ErrorOption | ErrorOption[];
  label?: string;
  name: Path<TFormValues>;
  placeholder?: string;
  register: UseFormRegister<TFormValues>;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
};

export const TextField = <TFormValues,>({
  errors,
  label,
  name,
  placeholder,
  register,
  required,
  type,
}: TextFieldProps<TFormValues>) => {
  return (
    <>
      <InputLabel name={name} required={required}>
        {label}
      </InputLabel>

      <Input
        placeholder={placeholder}
        type={type}
        isValid={Boolean(errors)}
        {...register(name)}
        min={0}
      />

      <ErrorLabel errors={errors} />
    </>
  );
};
