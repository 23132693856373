import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { getQuestionById, getQuizStatus } from '../data';
import { useAuth } from '../store';
import { Question, QuizResponse } from '../types';

const defaultQuestionQueryOptions: UseQueryOptions<
  Question,
  Error,
  Question,
  (string | undefined)[]
> = {
  refetchOnWindowFocus: false,
};

export const useQuestionQuery = (id?: string, options = defaultQuestionQueryOptions) => {
  const { accessToken } = useAuth();
  const enabled = Boolean(id) && Boolean(accessToken);

  return useQuery(['question', id], () => getQuestionById(id, accessToken), {
    ...defaultQuestionQueryOptions,
    ...options,
    enabled,
  });
};

const defaultQuizQueryOptions: UseQueryOptions<QuizResponse, Error, QuizResponse, 'quiz'> = {
  refetchOnWindowFocus: false,
  staleTime: 60 * 1000,
};

export const useQuizQuery = (options = defaultQuizQueryOptions) => {
  const { accessToken } = useAuth();
  const enabled = Boolean(accessToken);

  return useQuery('quiz', () => getQuizStatus(accessToken), {
    ...defaultQuizQueryOptions,
    ...options,
    enabled,
  });
};

export const useInvalidateQuizQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries('quiz');
  }, [queryClient]);
};
