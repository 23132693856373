import { FC } from 'react';
import { ErrorOption } from 'react-hook-form';

type ErrorLabelProps = {
  errors?: ErrorOption | ErrorOption[];
};

const ErrorLabelContent: FC = ({ children }) => {
  return <span className="text-sm pl-1 pr-1 text-red-400">{children}</span>;
};

export const ErrorLabel = ({ errors }: ErrorLabelProps) => {
  return (
    <span>
      {Array.isArray(errors) ? (
        errors.map(({ message }) => <ErrorLabelContent key={message}>{message}</ErrorLabelContent>)
      ) : (
        <ErrorLabelContent>{errors?.message}</ErrorLabelContent>
      )}
    </span>
  );
};
