import { ErrorOption, Path, UseFormRegister } from 'react-hook-form';
import { ErrorLabel } from '../ErrorLabel';
import { Input } from '../Input';
import { InputLabel } from '../InputLabel';

type DatePickerProps<TFormValues> = {
  errors?: ErrorOption | ErrorOption[];
  label?: string;
  name: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
  required?: boolean;
};

export const DatePicker = <TFormValues,>({
  errors,
  label,
  name,
  register,
  required,
}: DatePickerProps<TFormValues>) => {
  return (
    <>
      <InputLabel name={name} required={required}>
        {label}
      </InputLabel>

      <Input type="date" isValid={Boolean(errors)} {...register(name)} />

      <ErrorLabel errors={errors} />
    </>
  );
};
