import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '../TextField';
import { TutorialFormData } from '../../types';
import { Dropdown } from '../Dropdown';
import { Checkbox } from '../Checkbox';
import { useCountiesQuery, useTutorialSignUpMutation } from '../../queries';
import { LoadingSpiner } from '../LoadingSpiner';
import { Alert } from '../Alert';
import { Radio } from '../Radio';
import { tutorialFormSchema } from '../../validation';

export const TutorialForm = () => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<TutorialFormData & {}>({
    defaultValues: {
      student_count: '',
      selected_date: '14_martie',
    },
    mode: 'all',
    resolver: yupResolver(tutorialFormSchema),
  });

  const { data: counties } = useCountiesQuery();

  const { mutate: tutorialSignUp, isLoading: isSigningUpForTutorial } = useTutorialSignUpMutation();

  const [message, setMessage] = useState<string>();

  const onSubmit: SubmitHandler<TutorialFormData> = (data) => {
    tutorialSignUp(data, {
      onSuccess: () => {
        navigate('/');
      },
      onError: (error) => {
        if (error.errors) {
          Object.keys(error.errors).forEach((key: string) => {
            if (key === 'email') {
              setError(key, {
                type: 'required',
                message: 'Această adresă de email a fost folosită deja',
              });
            }
            if (key === 'phone') {
              setError(key, {
                type: 'required',
                message: 'Aceast număr de telefon a fost folosit deja',
              });
            }
          });
          return;
        }
        setMessage('Am întâmpinat o problemă. Vă rugăm verificați datele și încercați din nou.');
      },
    });
  };

  return (
    <div className="mt-10 sm:mt-0">
      <div className="mt-5 md:mt-0 max-w-[800px] mx-auto ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow bg-gray-50 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 sm:p-6">
              <div className="text-2xl mb-4">
                Formular de înscriere la întâlnirile de pregătire online
              </div>
              <h3 className="text-lg">Echipa</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8">
                  <TextField
                    errors={errors.team_name}
                    label="Nume"
                    name="team_name"
                    placeholder="Nume echipă"
                    register={register}
                    required
                  />
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg">Unitate de învățământ</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.school}
                    label="Nume"
                    name="school"
                    placeholder="Școala gimnazială nr. 1"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <TextField
                    errors={errors.locality}
                    label="Localitate"
                    name="locality"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-4 md:col-span-2">
                  <Dropdown
                    defaultValue=""
                    errors={errors.county_id}
                    label="Județ"
                    required
                    {...register(`county_id`)}
                  >
                    <option disabled value="">
                      -- județul --
                    </option>
                    {counties?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg">Coordonator</h3>
              <div className="grid grid-cols-8 gap-x-6">
                <div className="col-span-8 md:col-span-4">
                  <TextField
                    errors={errors.name}
                    label="Nume și prenume"
                    name="name"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-3 md:col-span-2">
                  <TextField
                    errors={errors.phone}
                    label="Număr telefon"
                    name="phone"
                    placeholder="0712345678"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-5 md:col-span-4">
                  <TextField
                    errors={errors.email}
                    label="Adresă e-mail"
                    name="email"
                    placeholder="coordonator@email.com"
                    register={register}
                    required
                  />
                </div>

                <div className="col-span-8 sm:col-span-5 md:col-span-4">
                  <TextField
                    errors={errors.student_count}
                    label="Cu câți elevi dorești să participi la întâlnirile de pregătire"
                    name="student_count"
                    register={register}
                    required
                    type="number"
                  />
                </div>
              </div>

              <div className="border-b border-slate-300 mb-4 w-full" />

              <h3 className="text-lg mb-2">
                Particip la întâlnirile de pregătire online (puteți selecta o singură opțiune):
              </h3>
              <div className="grid grid-cols-4 gap-y-2">
                <div className="col-span-12">
                  <Radio {...register('selected_date')} label="14 martie" value="14_martie" />
                </div>
                <div className="col-span-12">
                  <Radio {...register('selected_date')} label="15 martie" value="15_martie" />
                </div>
              </div>
            </div>

            <div className="px-4 py-3 sm:px-6">
              <Checkbox
                errors={errors.gdpr}
                label={`Confirm că dețin acordurile pentru utilizarea imaginii și GDPR pentru elevii care participă în întâlnire și, la cerere, le pun la dispoziția organizatorului pentru desfășurarea concursului Euro Quiz, ediția ${new Date().getFullYear()}.`}
                {...register('gdpr')}
              />
              <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-end">
                {message && (
                  <div className="sm:mr-auto">
                    <Alert message={message} type="error" />
                  </div>
                )}
                {isSigningUpForTutorial && <LoadingSpiner />}
                <button disabled={isSigningUpForTutorial} type="submit" className="primary-button">
                  Înscrie-te
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
