import cookie from 'js-cookie';
import { CookieKeys } from '../types';

export const setCookie = (key: CookieKeys, value: string) => {
  cookie.set(key, value, {
    expires: 1,
    path: '/',
  });
};

export const removeCookie = (key: CookieKeys) => {
  cookie.remove(key, {
    expires: 1,
  });
};

export const getCookie = (key: CookieKeys) => {
  return cookie.get(key);
};

export const setLocalStorage = (key: string, value: string) => {
  cookie.set(key, value);
};

export const getLocalStorage = (key: string) => {
  return cookie.get(key);
};
