import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getUser, resetPassword } from '../data';
import { useAuth } from '../store';
import { CookieKeys, ResetPasswordRequest, UserResponse } from '../types';
import { removeCookie } from '../utils/cookies';

type LoginError = {
  errors: {
    [key: string]: string[];
  };
  message: string;
};

export const useUserQuery = (): UseQueryResult<UserResponse, LoginError> => {
  const { accessToken, deAuthenticate } = useAuth();

  const enabled = Boolean(accessToken);

  return useQuery('user', () => getUser(accessToken ?? ''), {
    refetchOnWindowFocus: false,
    staleTime: 2 * 60 * 1000,
    enabled,
    onError: (error) => {
      if (error.errors) {
        removeCookie(CookieKeys.Token);
        deAuthenticate();
      }
    },
  });
};

export const useResetPasswordQuery = (
  data?: ResetPasswordRequest,
  options?: UseQueryOptions<ResetPasswordRequest, Error, unknown, 'reset-password'>,
) => {
  const enabled = Boolean(data?.email);

  return useQuery('reset-password', () => resetPassword(data), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    enabled,
    ...options,
  });
};
