import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { useNewPasswordMutation, useResetPasswordQuery } from '../../queries';
import { NewPasswordRequest, ResetPasswordRequest } from '../../types';
import { AppRoutes } from '../../types/routes';
import { newPasswordFormSchema, resetFormSchema } from '../../validation';
import { Alert } from '../Alert';
import { ErrorLabel } from '../ErrorLabel';
import { Input } from '../Input';
import { InputLabel } from '../InputLabel';
import { LoadingSpiner } from '../LoadingSpiner';
import { TextField } from '../TextField';

type AlertMessage = {
  text: string;
  type?: 'error' | 'info';
};

export const ResetPasswordForm = () => {
  const [message, setMessage] = useState<AlertMessage>();
  const [resetData, setResetData] = useState<ResetPasswordRequest>();

  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get('token');
  const email = searchParams.get('email');

  const {
    formState: { errors: errorsReset },
    handleSubmit: handleSubmitReset,
    register: registerSubmit,
  } = useForm<ResetPasswordRequest>({ mode: 'all', resolver: yupResolver(resetFormSchema) });

  const {
    formState: { errors: errorsUpdate },
    handleSubmit: handleSubmitUpdate,
    register: registerUpdate,
  } = useForm<NewPasswordRequest>({ mode: 'all', resolver: yupResolver(newPasswordFormSchema) });

  const { isLoading: isResettingPassword } = useResetPasswordQuery(resetData, {
    onSuccess: () => {
      setMessage({
        text: 'Veți primi un email în curând. Dați click pe link-ul primit pentru a vă reseta parola',
        type: 'info',
      });
    },
    onError: () => {
      setMessage({ text: 'Asigurați-vă că adresa de email introdusă este corectă', type: 'error' });
    },
  });

  const { mutate: updatePassword, isLoading: isUpdatingPassword } = useNewPasswordMutation();

  const onResetPassword: SubmitHandler<ResetPasswordRequest> = useCallback(
    (data: ResetPasswordRequest) => {
      setResetData(data);
    },
    [],
  );

  const onUpdatePassword: SubmitHandler<NewPasswordRequest> = useCallback(
    (data) => {
      if (resetToken && email) {
        updatePassword(
          { ...data, token: resetToken, email },
          {
            onSuccess: () => {
              setMessage({
                text: 'Parola a fost resetată cu success. Folosiți noua parola pentru a vă autentifica',
              });
            },
            onError: (error) => {
              if (error.errors) {
                Object.keys(error.errors).forEach((key: string) => {
                  if (key === 'token') {
                    setMessage({
                      text: 'Token-ul folosit este invalid',
                      type: 'error',
                    });
                  }
                });
                return;
              }

              setMessage({
                text: 'Am întâmpinat o problemă. Vă rugăm verificați datele și încercați din nou',
                type: 'error',
              });
            },
          },
        );
      }
    },
    [resetToken, email, updatePassword],
  );

  const isLoading = isResettingPassword || isUpdatingPassword;

  return (
    <div className="w-full bg-gray-50 rounded-lg px-4 py-4 sm:max-w-[500px]">
      {!resetToken ? (
        <>
          <div className="pb-4 text-xl">V-ați uitat parola?</div>
          <div className="pb-4 text-slate-500">
            Introduceți adresa de email folosită la crearea contului pentru a primi un email cu
            instrucțiunile de resetare a parolei
          </div>
          <form onSubmit={handleSubmitReset(onResetPassword)}>
            <InputLabel name="email">Adresă email</InputLabel>
            <Input
              disabled={isLoading}
              placeholder="coordonator@email.com"
              {...registerSubmit('email', { required: 'Email' })}
            />
            <ErrorLabel errors={errorsReset.email} />

            {message && (
              <div className="sm:mr-auto">
                <Alert message={message.text} type={message.type} />
              </div>
            )}

            <div className="flex items-center justify-end py-4">
              {isLoading && <LoadingSpiner />}
              <button disabled={isLoading} type="submit" className="primary-button">
                Resetați parola
              </button>
            </div>
          </form>
          <div className="text-left sm:text-center text-slate-500">
            Aveți un cont?{' '}
            <Link className="text-primary" to={AppRoutes.Login}>
              Autentificare
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="pb-4 text-xl">Resetați parola</div>
          <div className="pb-4 text-slate-500">Vă rugăm introduceți o parolă nouă</div>
          <form onSubmit={handleSubmitUpdate(onUpdatePassword)}>
            <TextField
              errors={errorsUpdate.password}
              label="Parolă"
              name="password"
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
              register={registerUpdate}
              required
              type="password"
            />

            <TextField
              errors={errorsUpdate.password_confirmation}
              label="Confirmare parolă"
              name="password_confirmation"
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
              register={registerUpdate}
              required
              type="password"
            />

            {message && (
              <div className="sm:mr-auto">
                <Alert message={message.text} type={message.type} />
              </div>
            )}

            <div className="flex items-center justify-end py-4">
              {isLoading && <LoadingSpiner />}
              <button disabled={isLoading} type="submit" className="primary-button">
                Salvați parola
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
