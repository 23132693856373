import { useMutation, UseMutationOptions } from 'react-query';
import { login, register, updatePassword } from '../data';
import { Authentication, Credentials, NewPasswordRequest, RegisterFormData } from '../types';

export type ValidationError = {
  errors: {
    [key: string]: string[];
  };
  message: string;
};

type RegisterMutationOptions = UseMutationOptions<
  Authentication,
  ValidationError,
  RegisterFormData
>;

export const useRegisterMutation = (options?: RegisterMutationOptions) => {
  return useMutation(register, { ...options });
};

type LoginMutationOptions = UseMutationOptions<Authentication, Error, Credentials>;

export const useLoginMutation = (options?: LoginMutationOptions) => {
  return useMutation(login, { ...options });
};

type NewPasswordMutationOptions = UseMutationOptions<unknown, ValidationError, NewPasswordRequest>;

export const useNewPasswordMutation = (options?: NewPasswordMutationOptions) => {
  return useMutation(updatePassword, { ...options });
};
