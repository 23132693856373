export const Footer = () => {
  return (
    <footer className="bg-gray-50 mt-auto">
      <div className="layout-container py-12 lg:py-16">
        <div className="grid gap-8 xl:grid-cols-2">
          <div className="text-gray-500 max-w-prose">
            <p>
              Pentru orice nelămurire sau solicitare de informații suplimentare, vă rugăm să
              utilizați următoarea adresă de e-mail:{' '}
              <a href="mailto:concurs@gopa-pace.com" className="font-medium underline">
                concurs@gopa-pace.com.
              </a>
            </p>
          </div>
        </div>

        <div className="pt-8 mt-8 border-t border-gray-200 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Euro Quiz.
          </p>
        </div>
      </div>
    </footer>
  );
};
