import {
  Authentication,
  Credentials,
  NewPasswordRequest,
  RegisterFormData,
  ResetPasswordRequest,
  UserResponse,
} from '../types';
import { endpoints } from '../common/endpoints';

export const register = async (data: RegisterFormData): Promise<Authentication> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.register}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();

    return Promise.reject(error || res.statusText);
  }

  return res.json();
};

export const login = async (data: Credentials): Promise<Authentication> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.login}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data, username: data.email }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const getUser = async (token: string): Promise<UserResponse> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.user}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const [user] = await res.json();

  return user;
};

export const resetPassword = async (data?: ResetPasswordRequest) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}${endpoints.reset}?email=${data?.email}`,
  );

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const updatePassword = async (data: NewPasswordRequest) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.reset}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();

    return Promise.reject(error || res.statusText);
  }

  return res.json();
};
