export type Credentials = {
  email: string;
  password: string;
};

export type Authentication = {
  access_token: string;
  token_type: 'Bearer';
  update_profile: boolean;
};

export enum CookieKeys {
  Token = 'token',
}

export type ResetPasswordRequest = {
  email: string;
};

export type NewPasswordRequest = {
  token?: string;
  email?: string;
  password: string;
  password_confirmation: string;
};
