import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { REGISTRATION_END_DATE } from '../../common/constants';
import { CompetitionCalendar, ContentWrapper } from '../../components';
import { AppRoutes } from '../../types/routes';

export const InfoPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.Quiz);
  }, [navigate]);

  return (
    <ContentWrapper>
      <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
        INFORMATII TEHNICE SI INTREBARI FRECVENTE PENTRU ORGANIZAREA ȘI DESFĂȘURAREA CONCURSULUI
        NAȚIONAL EURO QUIZ
      </h2>
      <br />
      <p className="mb-2">
        Pentru a intra în concurs, echipele, reprezentate de profesorii coordonatori, trebuie să
        completeze formularul de înscriere pe care îl vor găsi pe site-ul concursului, înainte de
        termenul limită menționat în calendarul concursului. Doar profesorul este cel care poate să
        înscrie echipa în concurs.
      </p>
      <p>
        Membrii echipelor trebuie să fie informați cu privire la regulile și instrucțiunile
        competiției înainte de începerea activităților în cadrul concursului.
      </p>
      <br />
      <h3 className="h3-title">Calendar Euro Quiz {new Date().getFullYear()} </h3>
      <CompetitionCalendar />
      <br />
      ````
      <h3 className="h3-title">Desfășurarea concursului național Euro Quiz</h3>
      <h4 className="h4-title">Întâlnirile online de informare si pregătire</h4>
      <p className="mb-2">
        Reprezentanța Comisiei Europene în România, în parteneriat cu Ministerul Educației,
        organizează întâlnirile online, care au ca scop informarea și prezentarea formatului și a
        specificului concursului național „Euro Quiz”. La aceste întâlniri online pot participa atât
        profesori cât și elevi. Participarea la aceste întâlniri de inform are nu atrage după sine
        obligația înscrierii în competiție.
      </p>
      <p className="mb-2">
        Până în data comunicată în calendar, profesorii trebuie să completeze tabelul de înscriere
        online, pentru înregistrarea participanților din fiecare unitate școlară cu ciclu gimnazial
        (clasele a V-a - a VIII-a) la una dintre cele două sesiuni de pregătire online desfășurate
        pe 23 și 24 aprilie 2024.
      </p>
      <p>
        În urma înscrierii, organizatorul va informa participanții, direct pe adresele de e-mail
        furnizate în formularul completat, în privința zilei, orele și detaliile derulării
        întâlnirilor online și va transmite link-ul de participare.
      </p>
      <br />
      <h4 className="h4-title">Înscrierea online la concurs</h4>
      <p className="mb-2">
        Până în data limită comunicată (
        {format(REGISTRATION_END_DATE, 'dd MMMM yyyy', {
          locale: ro,
        })}
        )), profesorul care coordonează echipa trebuie să acceseze site-ul concursului, să
        completeze Formularul de înscriere disponibil la{' '}
        <a href={AppRoutes.Register} className="text-primary underline">
          Creează cont
        </a>{' '}
        cu toate datele de contact solicitate, acceptând regulile și condițiile, acordul GDPR și
        Acordul de utilizare a imaginii pentru fiecare membru al echipei.
      </p>
      <p>
        Dacă toate cerințele formularului sunt îndeplinite corect, înscrierea va fi confirmată
        profesorului printr-un e-mail trimis automat de către sistem pe adresa furnizată în
        formular, împreună cu user-ul (numele echipei) și parola de logare în concursul național
        Euro Quiz {new Date().getFullYear()}.
      </p>
      <br />
      <h4 className="h4-title">Concursul online</h4>
      <p className="mb-2">
        Echipele înregistrate cu succes în competiție se vor întrece în cadrul etapei județene a
        concursului care se va desfășura online.
      </p>
      <p className="mb-2">
        Data și ora de desfășurare a etapei județene a concursului național Euro Quiz vor fi
        comunicate în mailul primit pentru confirmarea înscrierii.
      </p>
      <p className="mb-2">
        Accesul în platforma concursului nu se poate face cu niciun minut mai târziu decât ora
        comunicată pentru începerea acestuia.
      </p>
      <p className="mb-2">
        În cadrul concursului online, fiecare echipă va trebui să răspundă la un chestionar online
        ce include <strong>50 de întrebări</strong> cu privire la valorile UE, instituțiile
        europene, istorie, geografie, artă, gastronomie și cultură generală, într-un interval de
        timp limitat: <strong>25 de minute</strong> (o medie de 30 de secunde pentru fiecare
        întrebare). Întrebările sunt cu trei variante de răspuns, iar echipa trebuie să selecteze
        răspunsul corect (fiecare întrebare are un singur răspuns corect).
      </p>
      <p className="mb-2">
        Mai multe echipe în etapa națională! În cadrul etapei județene vor fi desemnate echipele
        calificate pentru etapa națională. În premieră, începând cu acest an, se califică toate
        echipele cu minimum 45 de răspunsuri corecte din fiecare județ/municipiul București.
      </p>
      <p className="mb-2">
        Lista echipelor calificate în etapă națională va fi publicată pe site-ul concursului.
      </p>
      <p className="mb-2">
        Echipele calificate în etapa națională confirmă prin logarea pe site participarea în etapă
        finală.
      </p>
      <p className="mb-2">
        Data și ora de desfășurare a etapei naționale a concursului național Euro Quiz vor fi
        comunicate în mailul primit pentru confirmarea înscrierii.
      </p>
      <p className="mb-2">
        Accesul în platforma concursului nu se poate face cu niciun minut mai târziu decât ora
        comunicată pentru începerea acestuia.
      </p>
      <p>
        În cadrul etapei naționale vor fi desemnate cele trei echipe câștigătoare ale concursului
        național Euro Quiz {new Date().getFullYear()}: locul I, locul al II lea și locul al III lea.
      </p>
      <br />
      <h4 className="h4-title">Premierea</h4>
      <p className="mb-2">
        Premierea concursului Euro Quiz {new Date().getFullYear()} va avea loc în București -
        detaliile vor fi comunicate ulterior. Evenimentul poate fi înregistrat și difuzat pe canale
        de social media.
      </p>
      <p className="mb-2">
        Dacă va fi necesară deplasarea la București (echipa câștigătoare este din afara capitalei),
        costurile de călătorie (transport) vor fi asigurate de către Inspectoratul Județean pentru
        toți membrii echipei și profesorul coordonator.
      </p>
      <p>Vor fi oferite premii fiecărui membru al echipelor câștigătoare.</p>
      <br />
      <h4 className="h4-title">Diplome și confirmare participare concurs</h4>
      <p className="mb-2">
        Este prevăzută acordarea unor diplome de participare pentru toți membrii echipelor, inclusiv
        a profesorilor, înscriși în concursul național Euro Quiz {new Date().getFullYear()}.
      </p>
      <p>
        Diplomele vor fi transmise profesorului coordonator pe adresa de e-mail menționată în
        formularul de înscriere în concurs după cum urmează: echipele care se opresc din concurs la
        finalul etapei județene vor primi diplomele pentru participare la etapa județeană, iar
        echipele care se califică și participa în etapa națională vor primi diplome pentru
        participare la etapa națională.
      </p>
      <br />
      <h4 className="h4-title">Responsabilitate și corectitudine</h4>
      <p className="mb-2">
        În contextul concursului național Euro Quiz, Reprezentanța Comisiei Europene în România și
        Ministerul Educației își rezervă dreptul de a descalifica și/sau de a exclude o echipă în
        cazul în care aceasta nu respectă regulile și condițiile sau în eventualitatea unui
        comportament incorect.
      </p>
      <p className="mb-2">
        Spiritul european, competiția corectă, respectul, prietenia, spiritul de echipă, egalitatea,
        respectul pentru reguli scrise și nescrise precum: integritatea, solidaritatea, toleranța,
        grija, excelența și bucuria, toate acestea sunt pietrele de temelie care sunt promovate prin
        intermediul concursului național Euro Quiz.
      </p>
      <p className="mb-2">
        Organizatorii își rezervă dreptul de a judeca orice situație neprevăzută sau modificare de
        reguli pe parcursul desfășurării competiției.
      </p>
      <p>
        Etapele concursului național „Euro Quiz” se desfășoară online și nu se admit contestații.
      </p>
      <br />
      <h4 className="h4-title">Declarație de Confidențialitate</h4>
      <p className="mb-2">
        Toți membrii echipei finaliste trebuie să prezinte o declarație completată și semnată, care
        reprezintă acordul lor cu privire la posibila utilizare a imaginilor ce ar putea fi filmate
        în timpul evenimentului de premiere și la utilizarea datelor personale pe canalele de
        comunicare ale campaniei (site-ul Reprezentanței Comisiei Europene în România, site-ul
        Ministerul Educației, paginile acestora de Facebook, Instagram, Youtube, media clasică sau
        neconvențională, fără fi limitate la acestea).
      </p>
      <p className="mb-2">
        Documentul trebuie să fie semnat de către părinți sau reprezentantul legal și să intre în
        posesia profesorului coordonator înainte de înscrierea echipei în concurs. Profesorul
        coordonator își asumă, prin acceptarea condițiilor, în momentul înscrierii echipei în
        concursul național Euro Quiz {new Date().getFullYear()} că deține și este împuternicit să
        cedeze organizatorului drepturile de imagine și GDPR așa cum sunt ele solicitate în
        regulamentul concursului.
      </p>
      <p>
        Organizatorul concursului își asumă respectarea confidențialității datelor cu caracter
        personal ale participanților. Datele personale nu vor fi divulgate și nu vor fi utilizate în
        alte scopuri care nu au legătură cu această campanie de informare sau cu desfășurarea
        concursului.
      </p>
      <br />
      <h3 className="h3-title">Help desk</h3>
      <p className="mb-2">
        Pentru mai multe detalii accesați:{' '}
        <a
          className="text-primary underline"
          href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
          target="_blank"
          rel="noreferrer"
        >
          https://romania.representation.ec.europa.eu/euro-quiz_ro
        </a>
        .
      </p>
      <p>
        Pentru orice nelămurire sau solicitare de informații suplimentare, vă rugăm să utilizați
        următoarea adresă de e-mail:{' '}
        <a className="text-primary underline" href="mailto:concurs@gopa-pace.com">
          concurs@gopa-pace.com
        </a>
        .
      </p>
      <br />
      <h3 className="h3-title">Întrebări frecvente</h3>
      <p className="italic font-semibold">Cum mă înscriu?</p>
      <p>
        Pentru a fi eligibilă în competiție, fiecare echipă trebuie să completeze, prin intermediul
        profesorului coordonator, formularul de pe site-ul concursului, cu datele:
      </p>
      <ul className="pl-4">
        <li>✓ Nume, localitate și județ al unității de învățământ pe care o reprezintă</li>
        <li>✓ Nume echipă - acesta va reprezenta și numele de utilizator în concursul online</li>
        <li>✓ Nume și prenume, număr de telefon și adresa e-mail pentru profesorul coordonator</li>
        <li>✓ Nume și prenume, clasa și data nașterii pentru fiecare membru al echipei</li>
        <li>✓ Confirmarea citirii și înțelegerii regulamentului concursului</li>
        <li>
          ✓ Confirmarea și aprobarea acordului pentru utilizare drepturi de imagine și GDPR pentru
          echipa înscrisă în concurs
        </li>
        <li>✓ Stabilire parolă de acces în platformă</li>
      </ul>
      <br />
      <p className="italic font-semibold">Câte echipe pot participa din fiecare școală?</p>
      <p>
        Nu există o limită impusă cu privire la numărul de echipe care pot participa la concurs din
        aceeași școală. Fiecare echipă trebuie să fie coordonată de un profesor diferit.
      </p>
      <br />
      <p className="italic font-semibold">
        Pot participa cu o echipă ai cărei membri provin din clase diferite?
      </p>
      <p>
        Da, echipa poate fi compusă din membri care provin din clasele a V-a și a VIII-a, diferite,
        din cadrul aceleiași școli.
      </p>
      <br />
      <p className="italic font-semibold">
        Sunt profesor, pot fi responsabil pentru mai multe echipe?
      </p>
      <p>Nu, un profesor poate coordona și poate fi responsabil doar pentru o singură echipă.</p>
      <br />
      <p className="italic font-semibold">Cum pot afla mai multe despre subiectul competiției?</p>
      <p>
        Materialele și resursele scrise sau video recomandate pentru studiu și simulare concurs sunt
        disponibile pe site:{' '}
        <a
          className="text-primary underline"
          href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
          target="_blank"
          rel="noreferrer"
        >
          https://romania.representation.ec.europa.eu/euro-quiz_ro
        </a>
        .
      </p>
      <br />
      <p className="italic font-semibold">Pot accesa informații online în timpul competiției?</p>
      <p>
        Da, puteți accesa resurse online sau citi cărți, dar țineți cont de faptul că cel de-al
        doilea criteriu important pentru a câștiga este respectarea timpului. De aceea, recomandăm
        să nu petreceți prea mult timp căutând răspunsul pentru fiecare întrebare.
      </p>
      <br />
      <p className="italic font-semibold">
        Va fi acordat vreun sprijin financiar pentru a acoperi costurile de transport pentru
        participarea la evenimentul de premiere?
      </p>
      <p>
        Celor patru membri ai echipei și profesorului coordonator, declarați câștigători, care vin
        din alt oraș decât București li se vor acoperi costurile de călătorie de către Inspectoratul
        județean.
      </p>
      <br />
      <p className="italic font-semibold">
        Dacă un membru al echipei, deja înscris, nu mai poate participa, restul echipei poate
        participa în continuare?
      </p>
      <p>
        Daca unul dintre cei patru membrii ai echipei nu mai poate participa în concurs, profesorul
        coordonator poate informa organizatorul despre situația apărută si îi va furniza acestuia
        numele elevului care nu mai poate participa si numele elevului care îl va înlocui în cadrul
        echipei.
      </p>
      <p>
        Organizatorul va fi singurul care are dreptul de a judeca cazurile neprevăzute și de a
        adapta regulile concursului în consecință.
      </p>
      <br />
      <p className="italic font-semibold">
        În cazul în care profesorul coordonator nu este disponibil, echipa poate participa la
        eveniment?
      </p>
      <p>
        Echipa poate participa la concurs după ce profesorul coordonator care a efectuat înscrierea
        a informat organizatorul despre situația apărută si îi solicită acestuia, prin furnizarea
        numelui profesorului care poate prelua echipa, înregistrarea schimbării. Pentru orice
        nelămurire sau solicitare de informații suplimentare, vă rugăm să utilizați următoarea
        adresă de e-mail:{' '}
        <a className="text-primary underline" href="mailto:concurs@gopa-pace.com">
          concurs@gopa-pace.com
        </a>
        .
      </p>
    </ContentWrapper>
  );
};
