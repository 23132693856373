import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

type RadioProps = {
  name: string;
  label?: string;
  helpText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ name, children, value, className, label, ...rest }, ref) => {
    return (
      <div className={clsx('flex items-center', className)}>
        <input
          type="radio"
          name={name}
          id={`${name}_${value}`}
          value={value}
          ref={ref}
          className={clsx(
            'appearance-none mr-2',
            'cursor-pointer',
            'w-4 h-4',
            'before:content-[" "] before:block',
            'border-gray-200 border-2 rounded-lg',
            'checked:border-blue-600 checked:bg-blue-600',
            'checked:bg-radio-selected bg-center bg-no-repeat',
          )}
          {...rest}
        />
        <label htmlFor={`${name}_${value}`} className="text-sm cursor-pointer block ">
          {label}
        </label>
      </div>
    );
  },
);
