import { FC } from 'react';
import { AppRoutes } from '../../types/routes';
import { NavLink } from '../NavLink';

type NavbarProps = {
  hidden?: boolean;
};

export const Navbar: FC<NavbarProps> = ({ hidden = false }) => {
  return (
    <nav className={`${hidden ? 'hidden md:flex gap-4' : 'flex flex-col px-5'} `}>
      {/* <NavLink to={AppRoutes.Home}>Acasă</NavLink> */}
      <NavLink to={AppRoutes.Quiz}>Concurs</NavLink>
      {/* <NavLink to={AppRoutes.Rules}>Regulament</NavLink> */}
      {/* <NavLink to={AppRoutes.Info}>Info utile</NavLink> */}
    </nav>
  );
};
