import { endpoints } from '../common/endpoints';
import { Team } from '../types';

export const updateUser = async ({ team_name, token }: Team & { token: string }) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.user}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'follow',
    body: JSON.stringify({ team_name }),
  });

  if (!res.ok) {
    const error = await res.json();

    return Promise.reject(error || res.statusText);
  }

  return res.json();
};
