import { UseMutationOptions, useMutation } from 'react-query';
import { tutorialSignUp } from '../data';
import { TutorialFormData } from '../types';
import { ValidationError } from './auth-mutations';

type TutorialMutationOptions = UseMutationOptions<
  { status: string },
  ValidationError,
  TutorialFormData
>;

export const useTutorialSignUpMutation = (options?: TutorialMutationOptions) => {
  return useMutation(tutorialSignUp, { ...options });
};
