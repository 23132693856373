import { useMemo } from 'react';
import { CONTEST_DATE } from '../../common/constants';
import { useQuizQuery } from '../../queries';
import { formatWordPlural, isContestEnded } from '../../utils';
import { Alert } from '../Alert';
import { LoadingSpiner } from '../LoadingSpiner';

const getMinutesFromMs = (ms: number) => {
  return Math.floor(ms / 60000);
};

export const ContestResults = () => {
  const { data: quizStatus, isLoading, isError } = useQuizQuery();

  const totalTime = useMemo(() => {
    if (quizStatus) {
      const lastResponseTime = new Date(quizStatus.last_response_time);

      return getMinutesFromMs(lastResponseTime.getTime() - CONTEST_DATE.getTime());
    }

    return 0;
  }, [quizStatus]);

  const isFinished = quizStatus?.total_number_of_responses === 50;

  const title = isFinished
    ? 'Felicitări pentru participare!'
    : 'Ne pare rău, timpul acordat pentru concurs s-a încheiat!';

  const message = isFinished
    ? 'Ați răspuns la toate cele 50 de întrebări adresate și ați înregistrat următorul rezultat:'
    : 'Nu ați reușit să răspundeți la toate cele 50 de întrebări.';

  const results = isFinished
    ? `${formatWordPlural(
        quizStatus.total_number_of_correct_responses,
        'un răspuns',
        'răspunsuri',
      )} corecte în ${formatWordPlural(totalTime, 'un minut', 'minute')}* !`
    : `Ați răspuns la ${formatWordPlural(
        quizStatus?.total_number_of_responses ?? 0,
        'o întrebare',
        'întrebări',
      )} în 25 de minute* .`;

  const finalMessage = isFinished
    ? 'Rămâneți pe fază! În scurt timp vom publica pe site-ul concursului lista echipelor calificate în etapa națională.'
    : 'Vă mulțumim pentru participare și vă așteptăm la următoarea ediție a concursului Euro Quiz!';

  if (!isContestEnded() && !isFinished) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="px-6 py-4 shadow-md flex flex-col">
        {isLoading && (
          <div className="flex items-center justify-center min-h-40 grow">
            <LoadingSpiner />
          </div>
        )}
        {!isLoading && !isError && (
          <>
            <div className="mb-4 text-xl">{title}</div>
            {isFinished && (
              <div className="mb-2 text-lg">
                Ați finalizat etapa județeană a concursului Euro Quiz {new Date().getFullYear()}!
              </div>
            )}
            <div className="mb-2 text-lg">Rezultat înregistrat de echipă:</div>
            <div className="mb-2">{message}</div>
            <div className="mb-2 font-semibold">{results}</div>
            <div className="mt-2">{finalMessage}</div>
            <div className="mt-4 italic text-sm">
              *Timpul este calculat de la momentul începerii concursului și până la momentul
              validării ultimului răspuns.
            </div>
          </>
        )}

        {isError && (
          <div className="max-w-2xl mx-auto">
            <Alert
              message="Ne pare rău. Rezultatele concursului nu au putut fi încărcate. Vă rugăm reîncărcați pagina."
              type="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};
