import { endpoints } from '../common/endpoints';
import { TutorialFormData } from '../types';

export const tutorialSignUp = async (data: TutorialFormData): Promise<{ status: string }> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.tutorial}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();

    return Promise.reject(error || res.statusText);
  }

  return res.json();
};
