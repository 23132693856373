import { Question, QuestionRequest, QuestionResponse, QuizResponse } from '../types';
import { endpoints } from '../common/endpoints';

export const getQuestionById = async (id?: string, token?: string): Promise<Question> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.question}/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const [data] = await res.json();

  return data;
};

export const answerQuestionById = async ({
  response,
  id,
  accessToken,
}: QuestionRequest): Promise<QuestionResponse> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.question}/${id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify({ response }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const data = await res.json();

  return data;
};

export const getQuizStatus = async (token?: string): Promise<QuizResponse> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.userQuiz}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const data = await res.json();

  return data;
};
