/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetState } from 'zustand';
import { Authentication, CookieKeys } from '../types';
import { getCookie } from '../utils/cookies';

export type AuthSlice = {
  access_token?: string;
  token_type: string;
  update_profile?: boolean;
  setAuth: (auth: Authentication) => void;
  deAuthenticate: () => void;
};

export const createAuthSlice = (set: SetState<AuthSlice>): AuthSlice => ({
  access_token: getCookie(CookieKeys.Token),
  token_type: '',
  setAuth: (auth) => {
    set(auth);
  },
  deAuthenticate: () => {
    set({ access_token: '', token_type: '' });
  },
});

export default { createPhoneNumberSlice: createAuthSlice };
