import { ContentWrapper, ResetPasswordForm } from '../../components';

export const ResetPage = () => {
  return (
    <ContentWrapper>
      <div className="flex justify-center">
        <ResetPasswordForm />
      </div>
    </ContentWrapper>
  );
};
