import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../queries';
import { useAuth } from '../../store';
import { CookieKeys, Credentials } from '../../types';
import { AppRoutes } from '../../types/routes';
import { isRegistrationEnded } from '../../utils';
import { setCookie } from '../../utils/cookies';
import { loginFormSchema } from '../../validation';
import { Alert } from '../Alert';
import { ErrorLabel } from '../ErrorLabel';
import { Input } from '../Input';
import { InputLabel } from '../InputLabel';
import { LoadingSpiner } from '../LoadingSpiner';

export const LoginForm = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const [message, setMessage] = useState<string>();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Credentials>({ resolver: yupResolver(loginFormSchema) });

  const { mutate: loginUser, isLoading } = useLoginMutation();

  const onSubmit: SubmitHandler<Credentials> = (data) => {
    loginUser(data, {
      onSuccess: (auth) => {
        setCookie(CookieKeys.Token, auth.access_token);
        setAuth(auth);
        if (auth.update_profile) {
          navigate('/user');
        } else {
          navigate('/');
        }
      },
      onError: () => {
        setMessage(
          'Asigurați-vă că datele introduse sunt corecte! În cazul în care nu v-ați înscris în concurs în perioada dedicată înscrierilor, nu vă puteți autentifica în platformă.',
        );
      },
    });
  };

  return (
    <div className="w-full bg-gray-50 rounded-lg px-4 py-4 sm:max-w-[500px]">
      <div className="text-xl pb-4">Autentificare</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel name="email">Adresă email</InputLabel>
        <Input
          disabled={isLoading}
          placeholder="coordonator@email.com"
          {...register('email', { required: 'Email' })}
        />
        <ErrorLabel errors={errors.email} />

        <InputLabel name="password">Parolă</InputLabel>
        <Input
          disabled={isLoading}
          placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
          type="password"
          {...register('password', { required: 'Email' })}
        />
        <ErrorLabel errors={errors.password} />

        {message && (
          <div className="sm:mr-auto">
            <Alert message={message} type="error" />
          </div>
        )}

        <div className="flex items-center justify-end py-4">
          {isLoading && <LoadingSpiner />}
          <button type="submit" className="primary-button">
            Autentificare
          </button>
        </div>
      </form>
      {!isRegistrationEnded() && (
        <div className="text-left sm:text-center text-slate-500">
          Nu aveți un cont?{' '}
          <Link className="text-primary" to={AppRoutes.Register}>
            Creează cont
          </Link>
        </div>
      )}
      <div className="text-left sm:text-center text-slate-500">
        V-ați uitat parola?{' '}
        <Link className="text-primary" to={AppRoutes.ResetPassword}>
          Resetează parola
        </Link>
      </div>
    </div>
  );
};
