import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { Grade, RegisterFormData, Team } from '../types';
import { phoneNumberRegex } from '../utils';

export const updateUserSchema: SchemaOf<Team> = yup.object().shape({
  team_name: yup.string().required('Vă rugăm introduceți numele echipei'),
});

export const registrationFormSchema: SchemaOf<RegisterFormData> = yup.object().shape({
  school: yup.string().required('Vă rugăm introduceți numele unității de învățământ'),
  locality: yup.string().required('Vă rugăm introduceți localitatea unității de învățământ'),
  county_id: yup.string().required('Vă rugăm introduceți județul unității de învățământ'),
  name: yup.string().required('Vă rugăm introduceți numele coordonatorului'),
  phone: yup
    .string()
    .required('Vă rugam introduceți numărul de telefon')
    .matches(phoneNumberRegex, 'Numărul de telefon în format invalid'),
  email: yup
    .string()
    .email('Vă rugăm introduceți o adresă de e-mail validă')
    .required('Vă rugăm introduceți adresa de e-mail'),
  password: yup
    .string()
    .min(8, 'Parola trebuie să aibă cel puțin 8 caractere')
    .required('Va rugam introduceti o parola'),
  password_confirmation: yup
    .string()
    .min(8, 'Parola trebuie să aibă cel puțin 8 caractere')
    .oneOf([yup.ref('password'), null], 'Parola nu coincide')
    .required('Vă rugăm confirmați parola'),
  students: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Vă rugăm introduceți numele elevului'),
      dob: yup.string().required('Vă rugăm introduceți data nașterii'),
      class: yup
        .mixed<Grade>()
        .oneOf(Object.values(Grade))
        .required('Vă rugăm introduceți clasa/anul de studiu'),
    }),
  ),
  team_name: yup.string().required('Vă rugăm introduceți numele echipei'),
  rules: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      'Pentru a merge mai departe trebuie să confirmați că ați citit și sunteți de acord cu regulamentul concursului.',
    ),
  gdpr: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      'Pentru a merge mai departe trebuie să vă dați acordul cu privire la procesarea datelor cu caracter personal',
    ),
});
