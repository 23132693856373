import { forwardRef, InputHTMLAttributes } from 'react';
import { ErrorOption } from 'react-hook-form';
import clsx from 'clsx';
import { ErrorLabel } from '../ErrorLabel';
import { InputLabel } from '../InputLabel';

type DropdownProps = {
  errors?: ErrorOption | ErrorOption[];
  label?: string;
  name: string;
  required?: boolean;
} & InputHTMLAttributes<HTMLSelectElement>;

export const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>(
  ({ children, errors, label, name, required, ...rest }, ref) => {
    return (
      <>
        <InputLabel name={name} required={required}>
          {label}
        </InputLabel>

        <select
          className={clsx('form-field', { 'form-field-error': errors })}
          name={name}
          ref={ref}
          {...rest}
        >
          {children}
        </select>

        <ErrorLabel errors={errors} />
      </>
    );
  },
);
