import { useEffect, useState } from 'react';
import { ro } from 'date-fns/locale';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components/ContentWrapper';
import {
  getCountdownData,
  getTutorialCountdownData,
  isContestEnded,
  isRegistrationEnded,
  isTutorialEnded,
  isTutorialInProgress,
  isTutorialRegistrationStarted,
} from '../../utils';
import euroQuizHero from '../../images/euro-quiz.jpg';
import { Countdown } from '../../components';
import { AppRoutes } from '../../types/routes';
import { CONTEST_DATE } from '../../common/constants';
// import { AppRoutes } from '../../types/routes';
import { getLocalStorage } from '../../utils/cookies';

export const Home = () => {
  const countdownData = getCountdownData();
  const tutorialCountdownData = getTutorialCountdownData();
  const [tutorialCountdownTime, setTutorialCountdownTime] = useState(tutorialCountdownData.time);
  const [tutorialCountdownTitle, setTutorialCountdownTitle] = useState(tutorialCountdownData.title);
  const [countdownTime, setCountdownTime] = useState(countdownData.time);
  const [countdownTitle, setCountdownTitle] = useState(countdownData.title);

  const demoTime = getLocalStorage('contest-date');
  const contestDate =
    demoTime && process.env.REACT_APP_DEMO === 'true' ? new Date(demoTime) : CONTEST_DATE;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const { title, time } = getCountdownData();
      const { title: tutorialTitle, time: tutorialTime } = getTutorialCountdownData();
      setCountdownTitle(title);
      setCountdownTime(time);
      setTutorialCountdownTitle(tutorialTitle);
      setTutorialCountdownTime(tutorialTime);
      if (isContestEnded()) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.Quiz);
  }, [navigate]);

  return (
    <ContentWrapper>
      {isTutorialInProgress() && (
        <div className="flex flex-col justify-center gap-3 text-center">
          <div className="text-xl text-slate-500">
            Întâlnirile de pregătire online sunt în desfășurare, nu uitați să vă înscrieți la
            concurs!
          </div>
        </div>
      )}
      <Countdown title={tutorialCountdownTitle} endTime={tutorialCountdownTime} />
      {isTutorialRegistrationStarted() && (
        <div className="flex flex-col justify-center gap-3 text-center">
          <div className="text-xl text-slate-500">
            <a href={AppRoutes.Tutorial} className="primary-button">
              Înscrie-te la întâlnirile online de informare
            </a>
          </div>
        </div>
      )}
      <Countdown
        extra="APĂSAȚI BUTONUL CONCURS DIN BARA DE MAI SUS PENTRU A FI PREGĂTIȚI LA ÎNCEPEREA CONCURSULUI!"
        title={countdownTitle}
        endTime={countdownTime}
      />
      {isTutorialEnded() && !isRegistrationEnded() && (
        <Countdown
          title={`Etapa județeană, ${format(contestDate, 'dd MMMM yyyy', {
            locale: ro,
          })}`}
          endTime={contestDate.getTime()}
        />
      )}

      <div className="flex flex-wrap flex-col lg:flex-row gap-6">
        <div className="basis-0 grow">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
            <span>Comisia Europeană si Ministerul Educației dau startul competiției</span>{' '}
            <span className="text-secondary whitespace-nowrap">Euro Quiz!</span>{' '}
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl font-bold mt-3 sm:mt-5">
            Concurs destinat elevilor claselor a V-a – a VIII-a.{' '}
            {!isRegistrationEnded() && (
              <span>
                Înscrie-te la concursul Euro Quiz prin completarea formularului tip, disponibil la{' '}
                <a href={AppRoutes.Register} className="text-primary">
                  Creează cont
                </a>
                .
              </span>
            )}
            {isRegistrationEnded() && <span>Perioada de înscriere s-a încheiat.</span>}
          </p>
          <p className="text-base text-gray-500 sm:text-lg md:text-xl">
            De ce să participi la Euro Quiz? Pentru că este o competiție în care îți poți testa
            cunoștințele generale, cu întrebări și răspunsuri despre istoria, prezentul și viitorul
            Uniunii Europene și care are drept scop dezvoltarea abilităților de lucru în echipă
            precum și conștientizarea valorilor europene.
          </p>
          <div className="mt-5 sm:mt-8 flex gap-4 flex-col sm:flex-row">
            <a
              className="secondary-button border-2"
              target="_blank"
              href="https://romania.representation.ec.europa.eu/euro-quiz_ro"
              rel="noreferrer"
            >
              Materiale
            </a>
            <a
              className="secondary-button border-2"
              target="_blank"
              href="https://learning-corner.learning.europa.eu/learning-corner_ro"
              rel="noreferrer"
            >
              Colțul învățării
            </a>
          </div>
        </div>

        <div className="order-3 lg:order-2 basis-0 grow drop-shadow-lg">
          <img className="rounded-xl" src={euroQuizHero} alt="" />
        </div>
      </div>
    </ContentWrapper>
  );
};
