import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { Credentials } from '../types';

export const loginFormSchema: SchemaOf<Credentials> = yup.object().shape({
  email: yup
    .string()
    .email('Vă rugăm introduceți o adresă de e-mail validă')
    .required('Vă rugăm introduceți adresa de e-mail'),
  password: yup.string().required('Va rugam introduceti parola'),
});
