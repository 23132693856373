import { useStore } from './useStore';

export const useAuth = () => {
  const accessToken = useStore((state) => state.access_token);
  const tokenType = useStore((state) => state.token_type);
  const setAuth = useStore((state) => state.setAuth);
  const deAuthenticate = useStore((state) => state.deAuthenticate);

  return { accessToken, tokenType, setAuth, deAuthenticate };
};
