import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Question, ResponseKeys } from '../../types';
import { AnswerBox } from '../AnswerBox';
import { LoadingSpiner } from '../LoadingSpiner';
import { CONTEST_DURATION, CONTEST_DATE } from '../../common/constants';
import { formatTime, getRemainingTime } from '../../utils';
import { Alert } from '../Alert';
import { getLocalStorage } from '../../utils/cookies';

type QuestionFormProps = {
  isError?: boolean;
  isLoading?: boolean;
  isValidating?: boolean;
  onAnswer: (response: ResponseKeys) => void;
  onAnswerChange?: (key: ResponseKeys) => void;
  question?: Question;
  selectedAnswer?: ResponseKeys;
};

export const QuestionForm: FC<QuestionFormProps> = ({
  isError,
  isLoading,
  isValidating,
  onAnswer,
  onAnswerChange,
  question,
  selectedAnswer,
}) => {
  const [remainingTime, setRemainingTime] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>();

  const onCheckAnswerHandler = () => {
    if (!selectedAnswer) return;

    onAnswer(selectedAnswer);
  };

  const onAnswerChangeHandler = useCallback(
    (key: ResponseKeys) => {
      onAnswerChange?.(key);
    },
    [onAnswerChange],
  );

  const remainingMinutesTotal = useMemo(
    () => (remainingTime?.hours ?? 0) * 60 + (remainingTime?.minutes ?? 0),
    [remainingTime?.hours, remainingTime?.minutes],
  );

  const demoTime = getLocalStorage('contest-date');
  const contestDate = useMemo(
    () => (demoTime && process.env.REACT_APP_DEMO === 'true' ? new Date(demoTime) : CONTEST_DATE),
    [demoTime],
  );

  useEffect(() => {
    const updateTime = () => {
      setRemainingTime({
        ...getRemainingTime(contestDate.getTime() + CONTEST_DURATION),
      });
    };

    updateTime();

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, [contestDate]);

  return (
    <div className="max-w-3xl mx-auto">
      <div className="px-6 py-4 shadow-md min-h-[400px] flex flex-col">
        {isLoading ? (
          <div className="flex items-center justify-center min-h-40 grow">
            <LoadingSpiner />
          </div>
        ) : (
          question && (
            <>
              <div className="mb-2 text-sm flex justify-between">
                <span>Întrebarea {question.id}/50</span>
                <span className="text-lg font-bold">
                  Timp rămas - {formatTime(remainingMinutesTotal)} :{' '}
                  {formatTime(remainingTime?.seconds)}
                </span>
              </div>
              <div className="mt-4 mb-10 text-2xl font-bold">{question.text}</div>

              <div className="flex flex-col gap-5 mb-3">
                <AnswerBox
                  label="a"
                  onClick={() => onAnswerChangeHandler('response_1')}
                  text={question.response_1}
                  selected={selectedAnswer === 'response_1'}
                />
                <AnswerBox
                  label="b"
                  onClick={() => onAnswerChangeHandler('response_2')}
                  text={question.response_2}
                  selected={selectedAnswer === 'response_2'}
                />
                <AnswerBox
                  label="c"
                  onClick={() => onAnswerChangeHandler('response_3')}
                  text={question.response_3}
                  selected={selectedAnswer === 'response_3'}
                />
              </div>

              <div className="text-right mt-4">
                <div className="flex justify-end items-center">
                  {isValidating && <LoadingSpiner />}
                  <button
                    disabled={isValidating || !selectedAnswer}
                    type="button"
                    className="primary-button"
                    onClick={onCheckAnswerHandler}
                  >
                    {question.id < 50 ? 'Următoarea întrebare' : 'Finalizare concurs'}
                  </button>
                </div>
              </div>
            </>
          )
        )}

        {isError && (
          <div className="max-w-3xl mx-auto mb-3">
            <Alert
              message="Ne pare rău. Am întâmpinat o problemă la încărcarea întrebării. Vă rugăm reîncărcați pagina"
              type="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};
