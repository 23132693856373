import { forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

type InputProps = {
  isValid?: boolean;
  name: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value?: string | number;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ isValid, name, placeholder, type = 'text', value, ...rest }, ref) => {
    return (
      <input
        placeholder={placeholder}
        className={clsx('form-field', { 'form-field-error': isValid })}
        id={name}
        name={name}
        ref={ref}
        type={type}
        value={value}
        {...rest}
      />
    );
  },
);
