import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { NewPasswordRequest, ResetPasswordRequest } from '../types';

export const resetFormSchema: SchemaOf<ResetPasswordRequest> = yup.object().shape({
  email: yup
    .string()
    .email('Vă rugăm introduceți o adresă de e-mail validă')
    .required('Vă rugăm introduceți adresa de e-mail'),
});

export const newPasswordFormSchema: SchemaOf<NewPasswordRequest> = yup.object().shape({
  token: yup.string(),
  email: yup.string().email('Vă rugăm introduceți o adresă de e-mail validă'),
  password: yup
    .string()
    .min(8, 'Parola trebuie să aibă cel puțin 8 caractere')
    .required('Va rugam introduceti o parola'),
  password_confirmation: yup
    .string()
    .min(8, 'Parola trebuie să aibă cel puțin 8 caractere')
    .oneOf([yup.ref('password'), null], 'Parola nu coincide')
    .required('Vă rugăm confirmați parola'),
});
