import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '../TextField';
import { Team } from '../../types';
import { useCountiesQuery, useUpdateUserMutation, useUserQuery } from '../../queries';
import { Alert } from '../Alert';
import { useAuth } from '../../store';
import { LoadingSpiner } from '../LoadingSpiner';
import { updateUserSchema } from '../../validation';

export const User = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Team>({
    mode: 'all',
    resolver: yupResolver(updateUserSchema),
  });

  const { accessToken } = useAuth();

  const queryClient = useQueryClient();

  const [message, setMessage] = useState<string>();

  const { data: user, isLoading: isLoadingUser, isFetching: isFetchingUser } = useUserQuery();

  const { data: counties } = useCountiesQuery();

  const { mutate: updateUser } = useUpdateUserMutation();

  const county = useMemo(
    () => counties?.find((c) => c.id === parseInt(user?.county_id ?? '-1', 10)),
    [counties, user?.county_id],
  );

  const onSubmit: SubmitHandler<{ team_name: string }> = (data) => {
    updateUser(
      { team_name: data.team_name, token: accessToken ?? '' },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('user');
        },
        onError: () => {
          setMessage('Am întâmpinat o problemă. Vă rugăm verificați datele și încercați din nou.');
        },
      },
    );
  };

  const isLoading = isLoadingUser || isFetchingUser;

  return (
    <div className="mt-10 sm:mt-0">
      <div className="mt-5 md:mt-0 max-w-[800px] mx-auto ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow bg-gray-50 overflow-hidden sm:rounded-md">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center min-h-[300px]">
                Vă rugăm așteptați
                <LoadingSpiner />
              </div>
            ) : (
              <div className="px-4 py-5 sm:p-6">
                <div className="text-2xl mb-4">Cont Euro Quiz</div>
                <div className="col-span-8 md:col-span-4">
                  {!user?.team_name ? (
                    <>
                      <Alert
                        message="Echipa nu are un nume. Vă rugăm alegeți un nume pentru echipă"
                        type="info"
                      />
                      <TextField
                        errors={errors.team_name}
                        label="Nume echipa"
                        name="team_name"
                        placeholder="Nume echipa"
                        register={register}
                        required
                      />
                      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-end">
                        {message && (
                          <div className="sm:mr-auto">
                            <Alert message={message} type="error" />
                          </div>
                        )}
                        {/* {isCreatingUser && <LoadingSpiner />} */}
                        <button type="submit" className="primary-button">
                          Salvează
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-slate-500 text-sm">Echipa</div>
                      <div className="text-lg">{user?.team_name}</div>
                    </>
                  )}
                </div>

                <h3 className="text-lg">Unitate de învățământ</h3>
                <div className="grid grid-cols-8 gap-x-6">
                  <div className="col-span-8 md:col-span-4">
                    <div className="text-slate-500 text-sm">Nume</div>
                    <div className="text-lg">{user?.school}</div>
                  </div>

                  <div className="col-span-8 sm:col-span-4 md:col-span-2">
                    <div className="text-slate-500 text-sm">Localitate</div>
                    <div className="text-lg">{user?.locality}</div>
                  </div>

                  <div className="col-span-8 sm:col-span-4 md:col-span-2">
                    <div className="text-slate-500 text-sm">Județ</div>
                    <div className="text-lg">{county?.name}</div>
                  </div>
                </div>

                <div className="border-b border-slate-300 my-4 w-full" />

                <h3 className="text-lg">Coordonator</h3>
                <div className="grid grid-cols-8 gap-x-6">
                  <div className="col-span-8 md:col-span-4">
                    <div className="text-slate-500 text-sm">Nume</div>
                    <div className="text-lg">{user?.name}</div>
                  </div>
                  <div className="col-span-8 md:col-span-4">
                    <div className="text-slate-500 text-sm">Număr telefon</div>
                    <div className="text-lg">{user?.phone}</div>
                  </div>
                  <div className="col-span-8 md:col-span-4">
                    <div className="text-slate-500 text-sm">Adresă e-mail</div>
                    <div className="text-lg">{user?.email}</div>
                  </div>
                </div>

                <div className="border-b border-slate-300 my-4 w-full" />

                <h3 className="text-lg">Echipa</h3>
                {user?.students.map((student) => (
                  <React.Fragment key={student.id}>
                    <div className="grid grid-cols-10 gap-x-6">
                      <div className="col-span-10 md:col-span-4">
                        <div className="text-slate-500 text-sm">Nume</div>
                        <div className="text-lg">{student?.name}</div>
                      </div>

                      <div className="col-span-10 sm:col-span-5 md:col-span-3">
                        <div className="text-slate-500 text-sm">Data nașterii</div>
                        <div className="text-lg">{student?.dob}</div>
                      </div>

                      <div className="col-span-10 sm:col-span-5 md:col-span-3">
                        <div className="text-slate-500 text-sm">Clasa / an de studiu</div>
                        <div className="text-lg">{student?.class}</div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
