import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper, LoginForm } from '../../components';
import { isContestEnded } from '../../utils';

export const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isContestEnded()) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  return (
    <ContentWrapper>
      <div className="flex justify-center">
        <LoginForm />
      </div>
    </ContentWrapper>
  );
};
