export type School = {
  school: string;
  locality: string;
  county_id: string;
};

export type Coordinator = {
  name: string;
  phone: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export enum Grade {
  Fifth = '5',
  Sixth = '6',
  Seventh = '7',
  Eighth = '8',
}

export type Student = {
  name: string;
  dob: string;
  class: Grade;
};

export type Team = {
  team_name: string;
};

export type RegisterFormData = Coordinator &
  School &
  Team & { students: Student[]; gdpr: boolean; rules: boolean };

export type TutorialFormData = Omit<Coordinator, 'password' | 'password_confirmation'> &
  School &
  Team & { student_count: string; gdpr: boolean; selected_date: '14_martie' | '15_martie' };
