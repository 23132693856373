import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ContentWrapper, ContestResults, Countdown } from '../../components';
import { useQuizQuery } from '../../queries';
import { useAuth } from '../../store';
import { AppRoutes } from '../../types/routes';
import { getContestCountdownData, isContestEnded, isContestStarted } from '../../utils';

export const QuizPage = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const countdownData = getContestCountdownData();

  const [countdownTitle, setCountdownTitle] = useState(countdownData.title);
  const [countdownTime, setCountdownTime] = useState(countdownData.time);

  const { data: quizStatus, isLoading, isError } = useQuizQuery();

  const goToQuestionPage =
    isContestStarted() &&
    accessToken &&
    !isContestEnded() &&
    (quizStatus?.total_number_of_responses ?? 0) < 50 &&
    !isLoading &&
    !isError;

  const [showCountdown, setShowCountdown] = useState(!goToQuestionPage);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (goToQuestionPage) {
        setShowCountdown(false);
        navigate(`${AppRoutes.Quiz}/${(quizStatus?.total_number_of_responses ?? 0) + 1}`);
        clearInterval(timerId);
      } else {
        const { title, time } = getContestCountdownData();
        setShowCountdown(true);
        setCountdownTitle(title);
        setCountdownTime(time);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [accessToken, goToQuestionPage, navigate, quizStatus?.total_number_of_responses]);

  return (
    <ContentWrapper>
      {showCountdown && <Countdown title={countdownTitle} endTime={countdownTime} />}

      {goToQuestionPage && (
        <div className="flex flex-col justify-center gap-3 text-center py-6 sm:py-8">
          <div className="text-xl text-bold">Vă rugăm așteptați!</div>
          <div>
            Veți fi redirecționat către pagina cu întrebări. În cazul în care nu se întâmplă acest
            lucru, vă rugăm dați click{' '}
            <Link className="text-primary underline" to={`${AppRoutes.Quiz}/1`}>
              aici
            </Link>
            .
          </div>
        </div>
      )}

      {accessToken && <ContestResults />}
    </ContentWrapper>
  );
};
