import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { TutorialFormData } from '../types';
import { phoneNumberRegex } from '../utils';

export const tutorialFormSchema: SchemaOf<TutorialFormData> = yup.object().shape({
  school: yup.string().required('Vă rugăm introduceți numele unității de învățământ'),
  locality: yup.string().required('Vă rugăm introduceți localitatea unității de învățământ'),
  county_id: yup.string().required('Vă rugăm introduceți județul unității de învățământ'),
  name: yup.string().required('Vă rugăm introduceți numele coordonatorului'),
  phone: yup
    .string()
    .required('Vă rugam introduceți numărul de telefon')
    .matches(phoneNumberRegex, 'Numărul de telefon în format invalid'),
  email: yup
    .string()
    .email('Vă rugăm introduceți o adresă de e-mail validă')
    .required('Vă rugăm introduceți adresa de e-mail'),
  student_count: yup.string().required('Vă rugam introduceți numărul de elevi'),
  team_name: yup.string().required('Vă rugăm introduceți numele echipei'),
  selected_date: yup.mixed().oneOf(['14_martie', '15_martie']).required('Vă rugăm selectați data'),
  gdpr: yup
    .boolean()
    .required()
    .oneOf(
      [true],
      'Pentru a merge mai departe trebuie să vă dați acordul cu privire la procesarea datelor cu caracter personal',
    ),
});
