import { forwardRef, InputHTMLAttributes } from 'react';
import { ErrorOption } from 'react-hook-form';
import { ErrorLabel } from '../ErrorLabel';
import { InputLabel } from '../InputLabel';

type CheckboxProps = {
  errors?: ErrorOption | ErrorOption[];
  label?: string;
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ errors, label, name, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        <div className="flex ">
          <input
            className="mr-2 bg-white before:content-[''] before:block h-5 cursor-pointer border-gray-200 border-2 rounded-sm bg-center bg-no-repeat"
            id={name}
            name={name}
            ref={ref}
            required
            type="checkbox"
            {...rest}
          />
          <InputLabel name={name} required>
            {label}
          </InputLabel>
        </div>
        <ErrorLabel errors={errors} />
      </div>
    );
  },
);
