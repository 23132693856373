import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../store';
import { AppRoutes } from '../../types/routes';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { accessToken } = useAuth();
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to={AppRoutes.Login} state={{ from: location }} replace />;
  }
  return children;
};
