import { Counties } from '../types/counties';
import { endpoints } from '../common/endpoints';

export const getCounties = async (): Promise<Counties> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}${endpoints.counties}`);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const { data } = await res.json();

  return data;
};
