import { FC } from 'react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

export const NavLink: FC<LinkProps> = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={`${
        match ? 'text-primary' : 'text-base'
      } font-medium text-gray-500 hover:text-primary-dark py-2`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
};
