import { FC } from 'react';
import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

type AlertProps = {
  message?: string;
  type?: 'error' | 'alert' | 'info' | 'success';
};

export const Alert: FC<AlertProps> = ({ message, type = 'info' }) => {
  if (!message) return null;

  const Icon = type === 'info' ? InformationCircleIcon : ExclamationIcon;

  return (
    <div
      className={clsx('rounded p-1 px-2 text-white flex gap-3 items-center', {
        'bg-blue-400': type === 'info',
        'bg-green-400': type === 'success',
        'bg-red-400': type === 'error',
      })}
    >
      <Icon className="shrink-0 h-6 w-6" />
      <span>{message}</span>
    </div>
  );
};
