import { ArrowCircleDownIcon } from '@heroicons/react/solid';
import { FC } from 'react';

type ListItemProps = {
  iconColor?: 'text-red-400' | 'text-green-400' | 'text-secondary';
  date: string;
  label: string;
};

const ListItem: FC<ListItemProps> = ({ iconColor = 'text-secondary', date, label }) => {
  return (
    <li className="flex justify-between gap-2 mb-1">
      <div className="flex flex-col h-full">
        <ArrowCircleDownIcon className={`${iconColor} h-6 w-6 shrink-0`} />
        <div className="border-l-2 grow items-stretch ml-3 my-2" />
      </div>
      <span className="text-sm mr-auto">{label}</span>
      <span className="text-sm text-slate-500 whitespace-nowrap">{date}</span>
    </li>
  );
};

export const CompetitionCalendar = () => {
  return (
    <ul className="mx-auto max-w-2xl">
      <ListItem
        iconColor="text-green-400"
        label="Înscrierea la sesiunile de pregătire ONLINE"
        date="8-22 aprilie"
      />
      <ListItem label="Sesiunile de pregătire ONLINE" date="23-24 aprilie" />
      <ListItem label="Înscrierea echipelor în concurs" date="25 aprilie - 15 mai" />
      <ListItem label="CONCURS - Etapa județeană" date="17 mai" />
      <ListItem label="Anunțarea echipelor calificate pentru etapa națională" date="20 mai" />
      <ListItem label="CONCURS - Etapa națională" date="24 mai" />
      <ListItem label="Anunțarea echipelor câștigătoare (locurile I, II, III)" date="27 mai" />
      <ListItem
        iconColor="text-red-400"
        label="Festivitatea de premiere (data exactă va fi anunțată ulterior)"
        date=""
      />
    </ul>
  );
};
