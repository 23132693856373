import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper, TutorialForm } from '../../components';
import { isTutorialRegistrationEnded, isTutorialRegistrationStarted } from '../../utils';

export const TutorialPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTutorialRegistrationEnded() || !isTutorialRegistrationStarted()) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  return (
    <ContentWrapper>
      <div className="flex justify-center">
        <TutorialForm />
      </div>
    </ContentWrapper>
  );
};
