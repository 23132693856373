import { FC } from 'react';

type LogoLinkProps = {
  alt?: string;
  logo: string;
  url: string;
};

export const LogoLink: FC<LogoLinkProps> = ({ alt, logo, url }) => {
  return (
    <a href={url} rel="noreferrer" target="_blank">
      <img alt={alt} className="h-[34px]" src={logo} />
    </a>
  );
};
