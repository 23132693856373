import { FC, useEffect, useState } from 'react';
import { formatTime, getRemainingTime } from '../../utils';

type CountdownProps = {
  endTime?: number;
  title?: string;
  extra?: string;
};

export const Countdown: FC<CountdownProps> = ({ endTime, title, extra }) => {
  const [remainingTime, setRemainingTime] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>();

  useEffect(() => {
    setRemainingTime({ ...getRemainingTime(endTime ?? 0) });

    const intervalId = setInterval(() => {
      setRemainingTime({ ...getRemainingTime(endTime ?? 0) });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  if (!title && !endTime) return null;

  return (
    <div className="flex flex-col justify-center gap-3 text-center py-6 sm:py-8">
      {extra && <div className="text-xl text-slate-500">{extra}</div>}
      {title && <div className="text-xl text-slate-500">{title}</div>}
      {endTime && (
        <div className="flex justify-center gap-6 sm:gap-10">
          <div className="flex flex-col">
            <span className="text-3xl sm:text-5xl">{formatTime(remainingTime?.days)}</span>
            <span className="text-slate-500">Zile</span>
          </div>
          <div className="flex flex-col">
            <span className="text-3xl sm:text-5xl">{formatTime(remainingTime?.hours)}</span>
            <span className="text-slate-500">Ore</span>
          </div>
          <div className="flex flex-col">
            <span className="text-3xl sm:text-5xl">{formatTime(remainingTime?.minutes)}</span>
            <span className="text-slate-500">Minute</span>
          </div>
          <div className="flex flex-col">
            <span className="text-3xl sm:text-5xl">{formatTime(remainingTime?.seconds)}</span>
            <span className="text-slate-500">Secunde</span>
          </div>
        </div>
      )}
    </div>
  );
};
